<template>
  <div>
    <div class="register-questionnaire-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-5 p-0">
            <div class="form-wrapper left-panel position-relative">
              <div class="logo">
                <router-link
                  :to="{ name: 'anonymousRoute-home' }"
                  class="logo-image"
                >
                  <img
                    src="/img/digital-staging-logo.svg"
                    alt="Digital Staging"
                  />
                </router-link>
              </div>
              <div
                class="register-profile-banner-mobile d-xs-block d-sm-block d-md-block d-lg-none"
              >
                <img src="/img/login-banner-mobile.png" alt="Sign up Banner" />
              </div>
              <div class="register-wrapper">
                <div class="register position-relative">
                  <h2 class="blue-text font-weight-bold">アンケート画面</h2>
                  <form method="POST" v-on:submit="registerQuestionare">
                    <div class="row">
                      <div class="col-12">
                        <div class="mt-4">
                          <p class="m-0 black-text font-weight-bold">
                            <span>属性</span>
                          </p>
                          <b-form-select
                            class="shadow-1"
                            v-model="client_attributes"
                            :options="getClientAttributes()"
                            required
                          ></b-form-select>
                        </div>
                      </div>
                      <div class="col-12">
                        <b-alert
                          class="m-0 mt-3 light-grey-outline grey-text"
                          variant="warning"
                          show
                          v-if="formMessage !== null"
                        >
                          {{ formMessage }}
                        </b-alert>
                      </div>
                      <div class="col-12 mt-5">
                        <button
                          class="btn btn-ds dark-blue white-text"
                          type="submit"
                          v-waves.light
                        >
                          プロフィール作成
                        </button>
                        <div class="mt-3 text-center">
                          <button
                            class="btn btn-ds light-grey white-text"
                            v-waves.light
                            v-on:click="skipQuestionnaire"
                          >
                            スキップ
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-12 col-lg-6 col-xl-7 p-0 d-none d-lg-block banner-wrap"
          >
            <img src="/img/login-banner.png" alt="Sign up Banner" />
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Services
import { cus_userService_UpdateUserQuestionnaire } from '../services/customer/users';

// Import Moment JS
import moment from 'moment';

// Important Custom Components
import mainFooter from '../components/customer/Main-Footer.vue';

//import common
import {
  getClientAttributes,
  getFloorPlanTypes,
  getBuildingType,
  getManagedPropertyNumbers,
} from '../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Sign Up',
    };
  },

  components: {
    'main-footer': mainFooter,
  },

  data() {
    return {
      client_attributes: null,
      floor_plan_type: null,
      building_type: null,
      managed_property_numbers: null,
      formMessage: null,
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
  },

  mounted() {},
  methods: {
    getClientAttributes,
    getFloorPlanTypes,
    getBuildingType,
    getManagedPropertyNumbers,
    registerQuestionare(e) {
      let _this = this;

      e.preventDefault();

      _this.$store.state.modalLoaderMessage = 'アカウントの作成中';
      _this.$bvModal.show('modal-loader');

      let accessToken = localStorage.getItem('_accessToken');

      cus_userService_UpdateUserQuestionnaire({
        id: _this.currentUser.id,
        client_attributes: _this.client_attributes,
        floor_plan_type: _this.floor_plan_type,
        building_type: _this.building_type,
        managed_property_numbers: _this.managed_property_numbers,
        token: accessToken,
      })
        .then(() => {
          _this.$router.push({
            name: 'anonymousRoute-register-success',
          });

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 1500);
        })
        .catch((error) => {
          console.log(error);

          _this.formMessage = 'エラーが起こりました。再度お試しください。 アカウントの初期化に失敗しました。';

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },

    skipQuestionnaire() {
      let _this = this;

      _this.$store.state.modalLoaderMessage = 'アカウントの作成中';
      _this.$bvModal.show('modal-loader');

      _this.$router.push({
        name: 'anonymousRoute-register-success',
      });

      setTimeout(() => {
        _this.$bvModal.hide('modal-loader');
      }, 1500);
    },
  },
};
</script>

<style scoped>
.hints ul {
  list-style: none;
  padding: 0;
  font-size: 12px;
}

.hint-enter {
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
}

.hint-leave-to {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

.hint-enter-active {
  transition: 300ms;
}

.hint-leave-active {
  transition: 400ms;
}

.register-questionnaire-wrapper .form-wrapper {
  padding: 42px;
  height: 100%;
}

@media (max-width: 991.98px) {
  .register-questionnaire-wrapper .form-wrapper {
    height: auto;
  }
}

.register-questionnaire-wrapper .form-wrapper .lets-start {
  background-color: #00adee;
  color: #fff;
  border-radius: 25px;
  position: absolute;
  right: -178px;
  padding: 15px 12px;
  width: 175px;
  text-align: center;
  height: 53px;
  margin: 5px 0;
}

.register-questionnaire-wrapper .form-wrapper .register-wrapper {
  padding: 40px 40px 10px 140px;
}

.register-questionnaire-wrapper .form-wrapper .register-wrapper .title-form {
  font-weight: 500;
}

.register-questionnaire-wrapper
  .form-wrapper
  .register-wrapper
  form
  .form-group {
  position: relative;
}

.register-questionnaire-wrapper
  .form-wrapper
  .register-wrapper
  form
  .btn-google {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bfbebe;
  border-radius: 100px;
  color: #707070;
}

.register-questionnaire-wrapper
  .form-wrapper
  .register-wrapper
  form
  .option-links
  p
  a,
.register-questionnaire-wrapper
  .form-wrapper
  .register-wrapper
  form
  .option-links
  a {
  color: #034ea1;
  text-decoration: underline !important;
}

.register-questionnaire-wrapper .form-wrapper .form-footer {
  padding: 10px 40px 10px 140px;
  position: absolute;
  margin: 1em auto;
  overflow: hidden;
  bottom: 0;
  width: 80%;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
}

.register-questionnaire-wrapper .form-wrapper .form-footer ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1px;
  list-style: none;
  padding-left: 0;
}

.register-questionnaire-wrapper .form-wrapper .form-footer ul li {
  flex-grow: 1;
  flex-basis: auto;
  margin: 0.25em 0;
  padding: 0 1em;
  text-align: center;
  border-left: 1px solid #ccc;
}

.register-questionnaire-wrapper .form-wrapper .form-footer ul li:first-child {
  border-left: 0 !important;
}

.register-questionnaire-wrapper .form-wrapper .form-footer ul li a {
  text-decoration: underline !important;
  color: #9b9b9b;
}

.register-questionnaire-wrapper .banner-wrap {
  overflow: hidden;
  padding: 0;
}

.register-questionnaire-wrapper .banner-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left center;
  object-position: left center;
}

@media (max-width: 991.98px) {
  .register-questionnaire-wrapper .form-wrapper {
    padding: 0 !important;
  }

  .register-questionnaire-wrapper .form-wrapper .logo {
    padding: 20px;
  }

  .register-questionnaire-wrapper .form-wrapper .logo .logo-image img {
    width: 172px;
  }

  .register-wrapper .form-wrapper .logo .lets-start {
    right: 20px !important;
    padding: 8px 10px;
    height: auto;
    margin: 0;
  }

  .register-questionnaire-wrapper .form-wrapper .register-wrapper {
    padding: 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    position: relative;
    top: -35px;
    background: #fff;
  }

  .register-questionnaire-wrapper .form-wrapper .form-footer {
    padding: 20px;
    position: relative !important;
    width: 100%;
  }

  .register-wrapper .form-wrapper .register-profile-banner-mobile img {
    width: 100%;
    height: 251px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .register-questionnaire-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .register-questionnaire-wrapper .form-wrapper .register-wrapper,
  .register-questionnaire-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 0 !important;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .register-questionnaire-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .register-questionnaire-wrapper .form-wrapper .register-wrapper,
  .register-questionnaire-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 40px !important;
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 0.5px solid #b8b8b8;
}

.separator:not(:empty)::before {
  margin-right: 8px;
}

.separator:not(:empty)::after {
  margin-left: 8px;
}
</style>
